



























































import { Component, Vue } from 'vue-property-decorator';
import state from './State';
import router from './router/index';

@Component({
  name: 'Login',
})
export default class Login extends Vue {
  model = { username: '', password: '' };
  error = { message: '' };

  clearForm() {
    this.error.message = '';
    this.model = { username: '', password: '' };
  }

  onSubmit() {
    const loginUrl = state.get('request.url.login') as string;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const params = state.get('request.params.login');
    const enc = encodeURIComponent;
    const body = [
      `${enc('username')}=${enc(this.model.username)}`,
      `${enc('password')}=${enc(this.model.password)}`,
    ].join('&');
    void fetch(loginUrl, {
      ...params,
      body,
    })
      .then((res) => res.json())
      .then((data: { user: string }) => {
        if (data.user) {
          state.update('user', data.user);
          void router.push('/licenses');
        } else {
          this.error.message = 'Wrong password';
        }
      });
  }
}
